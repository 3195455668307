body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-fluid {
  margin: 0 !important;
  padding: 0px !important;
  max-width: 960px;
  width: auto;
  margin: 0 auto !important;
}

.page-content {
  margin-top: 4px;
  margin-bottom: 20px;
  margin-left: 26px;
  margin-right: 26px;
}

.page-header-steps {
  margin-bottom: 6px !important;
}

.page-content-simple-spacer {
  height: 10px;
}

.center-block {
  display: table;  /* Instead of display:block */
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 400px) {
  .navbar-header {
      float: none;
  }
  .navbar-toggle {
      display: block;
  }
  .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-collapse.collapse {
      display: none!important;
  }
  .navbar-nav {
      float: none!important;
      margin: 7.5px -15px;
  }
  .navbar-nav>li {
      float: none;
  }
  .navbar-nav>li>a {
      padding-top: 10px;
      padding-bottom: 10px;
  }
}

/* fix menu close issue on mobile */
.navbar-collapse.collapse.in {
  display: block!important;
}

.navbar {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.navbar .nav-link {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.navbar-nav {
  font-weight: bold;
}

.navbar-dark .navbar-nav .nav-link:visited {
  color: #ccc !important;
}

.navbar-dark .navbar-nav .nav-link:link {
  color: #ccc !important;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #eee !important;
}

.page-navigation {
  margin-top: 24px;
}

.carousel .carousel-control-next { visibility: hidden; }
.carousel .carousel-control-prev { visibility: hidden; }
.carousel .carousel-indicators { visibility: hidden; }

.carousel-caption h3 {
  color: #ffffff;
  text-shadow: 0 0 2px #000000;
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.1);
  left: 0px !important;
  right: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.biller-card {
  background: rgba(0,0,0,.02) !important;
  border: 0px !important;
}

.personal-info-card {
  background: rgba(0,0,0,.02) !important;
  border: 0px !important;
}

.banking-info-card {
  background: rgba(0,0,0,.02) !important;
  border: 0px !important; 
}

.authorize-card {
  background: rgba(0,0,0,.02) !important;
  border: 0px !important; 
}

.biller-card .card-body {
  padding-top: 0px !important;
  margin-top: 4px !important;
  padding-bottom: 0px !important;
  margin-bottom: 4px !important;
}

.biller-card .card-body .card-title {
  padding-top: 0px !important;
  margin-top: 4px !important;
  padding-bottom: 0px !important;
  margin-bottom: 6px !important;
}

.biller-card .card-body .form-group {
  padding-top: 0px !important;
  margin-top: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 2px !important;
}

.biller-card-wrapper {
  margin-bottom: 8px;
}

.form-after-spacing {
  height: 12px;
}

.authorize-spacer {
  height: 12px;
}

.authorize-card-spacer {
  height: 6px;
}

.authorize-card-label {
  padding-right: 3px !important;
}

.authorize-card-value {
  padding-left: 3px  !important;
}

.authorize-warning {
  color: #d9534f !important;
}

.authorize-warning-header-icon {
  padding-right: 0px !important;
}

.authorize-card-edit {
  padding-top: 8px;
}

.authorize-card .card-body {
  padding-top: 0px !important;
  margin-top: 4px !important;
  padding-bottom: 0px !important;
  margin-bottom: 6px !important;
}

.authorize-card .card-body .card-title {
  padding-top: 0px !important;
  margin-top: 4px !important;
  padding-bottom: 0px !important;
  margin-bottom: 6px !important;
}

.authorize-edit-control {
  font-size: smaller;
}

.faq-question-row p {
  margin-bottom: 6px !important;
}

.faq-question-row div {
  margin-bottom: 6px !important;
}

.faq-question-col-head {
  padding-right: 6px !important;
}
.faq-question-col-text {
  padding-left: 6px !important;
}

.privacy-body .privacy-header {
  font-weight: 700;
}

.auth-code-label {
  font-weight: 700;

}

.privacy-subheader {
  text-decoration: underline;
}

.billerlist-table {
  margin: auto;
}

.billerlist-table td {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 1px solid black;
}

.print-spacer-header {
  height: 6px;
}

.print-info-value {
  padding-left: 8px;
}

.alert-no-state {
  padding-top: 20px;
  padding-bottom: 10px;
}

.summary-info-table {
  margin: auto;
}

.summary-info-table td {
  border-top: 1px solid rgba(0,0,0,.15);
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  text-align: left;
}

.summary-info-table > tbody > tr:last-child > td {
  border-bottom: 1px solid rgba(0,0,0,.15);
}

.summary-info-table > tbody > tr.summary-info-table-tr-no-border td {
  border-top: none;
}

.summary-info-table td:nth-child(1) {
  font-weight: 600;
}
